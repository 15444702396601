import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_DATA_METRICS,
  GET_DATA_METRICS_LOADING,
  GET_DATA_METRICS_SUCCESS,
  GET_DATA_METRICS_ERROR,
} from "./actionTypes";
import { api } from "utils/api";

function* getDataMetrics({ body }) {
    yield put({ type: GET_DATA_METRICS_LOADING });
    try {
      const { data } = yield call(api, {
        method: "GET",
        url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/data-metrics`,
        body,
      });
      yield put({
        type: GET_DATA_METRICS_SUCCESS,
        data: data,
      });
    } catch (error) {
      yield put({ type: GET_DATA_METRICS_ERROR, errorMessage: "" });
    }
  }

  export default function* watchResultSaga() {
    yield takeLatest(GET_DATA_METRICS, getDataMetrics);
  }