import { combineReducers } from "redux";
import {
  GET_CURRENT_USER_BY_ID_LOADING,
  GET_CURRENT_USER_BY_ID_SUCCESS,
  GET_CURRENT_USER_BY_ID_ERROR,
} from "./actionTypes";

const currentUser = (
  state = { loading: false, data: {}, success: false, error: false },
  action
) => {
  switch (action.type) {
    case GET_CURRENT_USER_BY_ID_LOADING: {
      return {
        loading: true,
        data: {},
        error: false,
      };
    }
    case GET_CURRENT_USER_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: false,
      };
    }
    case GET_CURRENT_USER_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: true,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentUser,
});
