import { combineReducers } from "redux";
import {
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_CLEAR,
  SEND_CONFIRMATION_CODE_CLEAR,
  SEND_CONFIRMATION_CODE_ERROR,
  SEND_CONFIRMATION_CODE_LOADING,
  SEND_CONFIRMATION_CODE_SUCCESS,
  VERIFY_CONFIRMATION_CODE_ERROR,
  VERIFY_CONFIRMATION_CODE_LOADING,
  VERIFY_CONFIRMATION_CODE_SUCCESS,
  RESEND_CONFIRMATION_CODE_LOADING,
  RESEND_CONFIRMATION_CODE_SUCCESS,
  RESEND_CONFIRMATION_CODE_ERROR,
} from "./actionTypes";

const confirmationCodeVerified = (
  state = { loading: false, data: false, error: "" },
  action
) => {
  switch (action.type) {
    case VERIFY_CONFIRMATION_CODE_LOADING: {
      return {
        loading: true,
        data: false,
        error: "",
      };
    }
    case VERIFY_CONFIRMATION_CODE_SUCCESS: {
      return {
        loading: false,
        data: true,
        error: "",
      };
    }
    case VERIFY_CONFIRMATION_CODE_ERROR: {
      return {
        loading: false,
        data: "",
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const confirmationCodeSent = (
  state = { loading: false, data: false, error: "" },
  action
) => {
  switch (action.type) {
    case SEND_CONFIRMATION_CODE_LOADING: {
      return {
        loading: true,
        data: false,
        error: "",
      };
    }
    case SEND_CONFIRMATION_CODE_SUCCESS: {
      return {
        loading: false,
        data: true,
        error: "",
      };
    }
    case SEND_CONFIRMATION_CODE_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    case SEND_CONFIRMATION_CODE_CLEAR: {
      return {
        loading: false,
        data: false,
        error: "",
      };
    }
    default:
      return state;
  }
};

const confirmationCodeResent = (
  state = { loading: false, data: false, error: "" },
  action
) => {
  switch (action.type) {
    case RESEND_CONFIRMATION_CODE_LOADING: {
      return {
        loading: true,
        data: false,
        error: "",
      };
    }
    case RESEND_CONFIRMATION_CODE_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case RESEND_CONFIRMATION_CODE_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const passwordChanged = (
  state = { loading: false, data: false, error: "" },
  action
) => {
  switch (action.type) {
    case RESET_PASSWORD_LOADING: {
      return {
        loading: true,
        data: false,
        error: "",
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        loading: false,
        data: true,
        error: "",
      };
    }
    case RESET_PASSWORD_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    case RESET_PASSWORD_CLEAR: {
      return {
        loading: false,
        data: false,
        error: "",
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  passwordChanged,
  confirmationCodeSent,
  confirmationCodeResent,
  confirmationCodeVerified,
});

