import { combineReducers } from "redux";
import {
  ADD_SETTINGS_LOADING,
  ADD_SETTINGS_SUCCESS,
  ADD_SETTINGS_ERROR,
  ADD_SETTINGS_CLEAR,
  UPATE_SETTINGS_LOADING,
  UPATE_SETTINGS_SUCCESS,
  UPATE_SETTINGS_ERROR,
  UPATE_SETTINGS_CLEAR,
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
  GET_SETTINGS_CLEAR,
} from "./actionTypes";

const settings = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_SETTINGS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_SETTINGS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_SETTINGS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case GET_SETTINGS_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const update = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case UPATE_SETTINGS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case UPATE_SETTINGS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case UPATE_SETTINGS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case UPATE_SETTINGS_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const add = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case ADD_SETTINGS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case ADD_SETTINGS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case ADD_SETTINGS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case ADD_SETTINGS_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  add,
  update,
  settings,
});
