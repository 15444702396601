import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_WEB_SCRAPING_WAITLIST,
  GET_ALL_WEB_SCRAPING_WAITLIST_LOADING,
  GET_ALL_WEB_SCRAPING_WAITLIST_SUCCESS,
  GET_ALL_WEB_SCRAPING_WAITLIST_ERROR,
  ADD_WEB_SCRAPING_WAITLIST,
  ADD_WEB_SCRAPING_WAITLIST_LOADING,
  ADD_WEB_SCRAPING_WAITLIST_SUCCESS,
  ADD_WEB_SCRAPING_WAITLIST_ERROR,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR,
} from "./actionTypes";
import { api } from "utils/api";
import { message } from "antd";

function* getScrapingWaitlist({ body }) {
  yield put({ type: GET_ALL_WEB_SCRAPING_WAITLIST_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: `https://m2yfkvoit7.execute-api.us-east-1.amazonaws.com/dev/`,
      body,
    });
    yield put({
      type: GET_ALL_WEB_SCRAPING_WAITLIST_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_ALL_WEB_SCRAPING_WAITLIST_ERROR, errorMessage: "" });
  }
}

function* addScrapingWaitlist({ body }) {
  yield put({ type: ADD_WEB_SCRAPING_WAITLIST_LOADING });
  try {
    yield call(api, {
      method: "POST",
      url: `https://m2yfkvoit7.execute-api.us-east-1.amazonaws.com/dev/`,
      body,
    });
    yield put({
      type: GET_ALL_WEB_SCRAPING_WAITLIST,
    });
    yield put({
      type: ADD_WEB_SCRAPING_WAITLIST_SUCCESS,
      data: { added: true },
    });
    message.success("URL added successfully in the waitlist!");
  } catch (error) {
    yield put({ type: ADD_WEB_SCRAPING_WAITLIST_ERROR, errorMessage: "" });
  }
}

function* updateScrapingWaitlistById({ body }) {
  yield put({ type: UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING });
  try {
    yield call(api, {
      method: "PUT",
      url: `https://m2yfkvoit7.execute-api.us-east-1.amazonaws.com/dev/id/{id}`,
      body,
    });
    yield put({
      type: GET_ALL_WEB_SCRAPING_WAITLIST,
    });
    yield put({
      type: UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS,
      data: { updated: true },
    });
    message.success("description updated successfully for the waitlist!");
  } catch (error) {
    yield put({ type: UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR, errorMessage: "" });
  }
}

function* deleteScrapingWaitlistById({ body }) {
  delete body.profileId;
  yield put({ type: DELETE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING });
  try {
    yield call(api, {
      method: "DELETE",
      url: `https://m2yfkvoit7.execute-api.us-east-1.amazonaws.com/dev/id/{id}`,
      body,
    });
    yield put({
      type: GET_ALL_WEB_SCRAPING_WAITLIST,
    });
    yield put({
      type: DELETE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS,
      data: { deleted: true },
    });
    message.success("URL deleted successfully from the waitlist!");
  } catch (error) {
    yield put({ type: DELETE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR, errorMessage: "" });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchWaitlistSagar() {
  yield takeLatest(ADD_WEB_SCRAPING_WAITLIST, addScrapingWaitlist);
  yield takeLatest(GET_ALL_WEB_SCRAPING_WAITLIST, getScrapingWaitlist);
  yield takeLatest(UPDATE_WEB_SCRAPING_WAITLIST_BY_ID, updateScrapingWaitlistById);
  yield takeEvery(DELETE_WEB_SCRAPING_WAITLIST_BY_ID, deleteScrapingWaitlistById);
}
