import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_RESULTS,
  GET_ALL_RESULTS_LOADING,
  GET_ALL_RESULTS_SUCCESS,
  GET_ALL_RESULTS_ERROR,
  ADD_NOTE,
  ADD_NOTE_LOADING,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_ERROR,
  GET_CHAT_SEARCH_RESULTS,
  GET_CHAT_SEARCH_RESULTS_LOADING,
  GET_CHAT_SEARCH_RESULTS_SUCCESS,
  GET_CHAT_SEARCH_RESULTS_ERROR,
  GET_CHAT_DISCOVERY_RESULTS,
  GET_CHAT_DISCOVERY_RESULTS_LOADING,
  GET_CHAT_DISCOVERY_RESULTS_SUCCESS,
  GET_CHAT_DISCOVERY_RESULTS_ERROR,
  CREATE_RESULT,
  CREATE_RESULT_LOADING,
  CREATE_RESULT_SUCCESS,
  CREATE_RESULT_ERROR,
  GET_RESULT_BY_RESULT_ID,
  GET_RESULT_BY_RESULT_ID_LOADING,
  GET_RESULT_BY_RESULT_ID_SUCCESS,
  GET_RESULT_BY_RESULT_ID_ERROR,
  UPATE_RESULT_BY_ID,
  UPATE_RESULT_BY_ID_LOADING,
  UPATE_RESULT_BY_ID_SUCCESS,
  UPATE_RESULT_BY_ID_ERROR,
  GET_ALL_CPP_PROFILES,
  GET_ALL_CPP_PROFILES_LOADING,
  GET_ALL_CPP_PROFILES_SUCCESS,
  GET_ALL_CPP_PROFILES_ERROR,
  GET_CPP_PROFILE_BY_ID,
  GET_CPP_PROFILE_BY_ID_LOADING,
  GET_CPP_PROFILE_BY_ID_SUCCESS,
  GET_CPP_PROFILE_BY_ID_ERROR,
  UPDATE_CPP_PROFILE_BY_ID,
  UPDATE_CPP_PROFILE_BY_ID_LOADING,
  UPDATE_CPP_PROFILE_BY_ID_SUCCESS,
  UPDATE_CPP_PROFILE_BY_ID_ERROR,
  GET_REVIEW_CPP_PROFILE_BY_ID,
  GET_REVIEW_CPP_PROFILE_BY_ID_LOADING,
  GET_REVIEW_CPP_PROFILE_BY_ID_SUCCESS,
  GET_REVIEW_CPP_PROFILE_BY_ID_ERROR,
  GET_DATA_SOURCE_PROJECT_BY_ID,
  GET_DATA_SOURCE_PROJECT_BY_ID_LOADING,
  GET_DATA_SOURCE_PROJECT_BY_ID_SUCCESS,
  GET_DATA_SOURCE_PROJECT_BY_ID_ERROR,
  DELETE_DATA_SOURCE_PROJECT_BY_ID,
  DELETE_DATA_SOURCE_PROJECT_BY_ID_LOADING,
  DELETE_DATA_SOURCE_PROJECT_BY_ID_SUCCESS,
  DELETE_DATA_SOURCE_PROJECT_BY_ID_ERROR,
  COMBINE_PROFILE,
  COMBINE_PROFILE_LOADING,
  COMBINE_PROFILE_SUCCESS,
  COMBINE_PROFILE_ERROR,
} from "./actionTypes";
import { api } from "utils/api";
import { message } from "antd";

function* getResults({ body }) {
  yield put({ type: GET_ALL_RESULTS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: `https://61y16po69i.execute-api.us-east-1.amazonaws.com/dev/`,
      body,
    });
    yield put({
      type: GET_ALL_RESULTS_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_ALL_RESULTS_ERROR, errorMessage: "" });
  }
}

function* createResult({ body }) {
  yield put({ type: CREATE_RESULT_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `https://61y16po69i.execute-api.us-east-1.amazonaws.com/dev/`,
      body,
    });
    yield put({
      type: GET_ALL_RESULTS,
    });
    yield put({
      type: GET_RESULT_BY_RESULT_ID,
      body: { id: data.resultId },
    });
    yield put({
      type: CREATE_RESULT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: CREATE_RESULT_ERROR, errorMessage: "" });
  }
}

function* updateResultById({ body }) {
  const { resultId } = body;
  yield put({ type: UPATE_RESULT_BY_ID_LOADING });
  try {
    yield call(api, {
      method: "PUT",
      url: `https://61y16po69i.execute-api.us-east-1.amazonaws.com/dev/id/{id}`,
      body,
    });
    yield put({
      type: GET_ALL_RESULTS,
    });
    yield put({
      type: GET_RESULT_BY_RESULT_ID,
      body: { id: resultId },
    });
    yield put({
      type: UPATE_RESULT_BY_ID_SUCCESS,
    });
  } catch (error) {
    yield put({ type: UPATE_RESULT_BY_ID_ERROR, errorMessage: "" });
  }
}

function* getCPPProfiles({ body }) {
  yield put({ type: GET_ALL_CPP_PROFILES_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/CPP-profiles`,
      body,
    });
    yield put({
      type: GET_ALL_CPP_PROFILES_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_ALL_CPP_PROFILES_ERROR, errorMessage: "" });
  }
}

function* getResultByResultId({ body }) {
  yield put({ type: GET_RESULT_BY_RESULT_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: "https://61y16po69i.execute-api.us-east-1.amazonaws.com/dev/id/{id}",
      body,
    });
    yield put({
      type: GET_RESULT_BY_RESULT_ID_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_RESULT_BY_RESULT_ID_ERROR, errorMessage: "" });
  }
}

function* getCPPProfileById({ body }) {
  yield put({ type: GET_CPP_PROFILE_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/CPP-profiles/{id}`,
      body,
    });
    yield put({
      type: GET_CPP_PROFILE_BY_ID_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_CPP_PROFILE_BY_ID_ERROR, errorMessage: "" });
  }
}

function* updateCPPProfileById({ body }) {
  const { uuid } = body;
  yield put({ type: UPDATE_CPP_PROFILE_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "PUT",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/CPP-profiles/{uuid}/update-profile`,
      body,
    });
    yield put({
      type: UPDATE_CPP_PROFILE_BY_ID_SUCCESS,
      data: data,
    });
    yield put({
      type: GET_CPP_PROFILE_BY_ID,
      body: { id: uuid },
    });
  } catch (error) {
    yield put({ type: UPDATE_CPP_PROFILE_BY_ID_ERROR, errorMessage: "" });
  }
}

function* getReviewCPPProfileById({ body }) {
  const { isConfirmed } = body;
  yield put({ type: GET_REVIEW_CPP_PROFILE_BY_ID_LOADING });
  try {
    yield call(api, {
      method: "PUT",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/CPP-profiles/{id}/profile-reviewed`,
      body,
    });
    message.success(
      isConfirmed
        ? "Profile marked as confirmed successfully"
        : "Profile marked as rejected successfully"
    );
    yield put({ type: GET_ALL_CPP_PROFILES });
    yield put({
      type: GET_REVIEW_CPP_PROFILE_BY_ID_SUCCESS,
    });
  } catch (error) {
    yield put({ type: GET_REVIEW_CPP_PROFILE_BY_ID_ERROR, errorMessage: "" });
  }
}

function* getDataSourceProjectById({ body }) {
  const { profileId } = body;
  delete body.profileId;
  yield put({ type: GET_DATA_SOURCE_PROJECT_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/data-source-projects/{id}`,
      body,
    });
    yield put({
      type: GET_DATA_SOURCE_PROJECT_BY_ID_SUCCESS,
      data: { data, profileId },
    });
  } catch (error) {
    yield put({ type: GET_DATA_SOURCE_PROJECT_BY_ID_ERROR, errorMessage: "" });
  }
}

function* addNote({ body }) {
  yield put({ type: ADD_NOTE_LOADING });
  try {
    yield call(api, {
      method: "POST",
      url: `https://61y16po69i.execute-api.us-east-1.amazonaws.com/dev/`,
      body,
    });
    yield put({
      type: GET_ALL_RESULTS,
    });
    yield put({
      type: ADD_NOTE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: ADD_NOTE_ERROR, errorMessage: "" });
  }
}

function* combineProfile({ body }) {
  const { uuid } = body;
  yield put({ type: COMBINE_PROFILE_LOADING });
  try {
    const { data } = yield call(api, {
      method: "PUT",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/CPP-profiles/{uuid}/combine-profiles/{profileToBeCombinedUUID}`,
      body,
    });
    yield put({
      type: COMBINE_PROFILE_SUCCESS,
      data: data,
    });
    yield put({
      type: GET_CPP_PROFILE_BY_ID,
      body: { id: uuid },
    });
    yield put({
      type: GET_RESULT_BY_RESULT_ID,
      body: { id: uuid },
    });
    yield put({
      type: GET_ALL_RESULTS,
    });
    yield put({
      type: GET_ALL_CPP_PROFILES,
    });
    message.success("Profile merged successfully");
  } catch (error) {
    yield put({ type: COMBINE_PROFILE_ERROR, errorMessage: "" });
  }
}

function* getChatSearchResults({ body }) {
  yield put({ type: GET_CHAT_SEARCH_RESULTS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/chat/search`,
      body,
    });
    yield put({
      type: GET_CHAT_SEARCH_RESULTS_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_CHAT_SEARCH_RESULTS_ERROR, errorMessage: "" });
  }
}

function* getChatDiscoveryResults({ body }) {
  yield put({ type: GET_CHAT_DISCOVERY_RESULTS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/chat/discovery`,
      body,
    });
    yield put({
      type: GET_CHAT_DISCOVERY_RESULTS_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_CHAT_DISCOVERY_RESULTS_ERROR, errorMessage: "" });
  }
}

function* deleteDataSourceProjectById({ body }) {
  const { profileId } = body;
  yield put({ type: DELETE_DATA_SOURCE_PROJECT_BY_ID_LOADING });
  try {
    yield call(api, {
      method: "DELETE",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/CPP-profiles/{profileId}/source-projects/{projectId}`,
      body,
    });
    yield put({
      type: DELETE_DATA_SOURCE_PROJECT_BY_ID_SUCCESS,
    });
    yield put({
      type: GET_CPP_PROFILE_BY_ID,
      body: { id: profileId },
    });
    yield put({
      type: GET_RESULT_BY_RESULT_ID,
      body: { id: profileId },
    });
  } catch (error) {
    yield put({
      type: DELETE_DATA_SOURCE_PROJECT_BY_ID_ERROR,
      errorMessage: "",
    });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchResultSaga() {
  yield takeLatest(ADD_NOTE, addNote);
  yield takeLatest(GET_CHAT_SEARCH_RESULTS, getChatSearchResults);
  yield takeLatest(GET_CHAT_DISCOVERY_RESULTS, getChatDiscoveryResults);
  yield takeLatest(GET_ALL_RESULTS, getResults);
  yield takeLatest(CREATE_RESULT, createResult);
  yield takeLatest(COMBINE_PROFILE, combineProfile);
  yield takeLatest(UPATE_RESULT_BY_ID, updateResultById);
  yield takeLatest(GET_ALL_CPP_PROFILES, getCPPProfiles);
  yield takeLatest(GET_CPP_PROFILE_BY_ID, getCPPProfileById);
  yield takeLatest(GET_RESULT_BY_RESULT_ID, getResultByResultId);
  yield takeLatest(UPDATE_CPP_PROFILE_BY_ID, updateCPPProfileById);
  yield takeLatest(GET_REVIEW_CPP_PROFILE_BY_ID, getReviewCPPProfileById);
  yield takeEvery(GET_DATA_SOURCE_PROJECT_BY_ID, getDataSourceProjectById);
  yield takeEvery(
    DELETE_DATA_SOURCE_PROJECT_BY_ID,
    deleteDataSourceProjectById
  );
}
