import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_SETTINGS,
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
  ADD_SETTINGS,
  ADD_SETTINGS_LOADING,
  ADD_SETTINGS_SUCCESS,
  ADD_SETTINGS_ERROR,
  UPATE_SETTINGS,
  UPATE_SETTINGS_LOADING,
  UPATE_SETTINGS_SUCCESS,
  UPATE_SETTINGS_ERROR,

} from "./actionTypes";
import { api } from "utils/api";

function* getSettings({ body }) {
  yield put({ type: GET_SETTINGS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: `https://t2qu4ml492.execute-api.us-east-1.amazonaws.com/dev/`,
      body,
    });
    yield put({
      type: GET_SETTINGS_SUCCESS,
      data: data?.list[0] || {},
    });
  } catch (error) {
    yield put({ type: GET_SETTINGS_ERROR, errorMessage: "" });
  }
}

function* updateSettingById({ body }) {
  yield put({ type: UPATE_SETTINGS_LOADING });
  try {
    yield call(api, {
      method: "PUT",
      url: `https://t2qu4ml492.execute-api.us-east-1.amazonaws.com/dev/id/{id}`,
      body,
    });
    yield put({
      type: GET_SETTINGS,
    });
    yield put({
      type: UPATE_SETTINGS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: UPATE_SETTINGS_ERROR, errorMessage: "" });
  }
}

function* addSetting({ body }) {
  yield put({ type: ADD_SETTINGS_LOADING });
  try {
    yield call(api, {
      method: "POST",
      url: `https://t2qu4ml492.execute-api.us-east-1.amazonaws.com/dev/`,
      body,
    });
    yield put({
      type: GET_SETTINGS,
    });
    yield put({
      type: ADD_SETTINGS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: ADD_SETTINGS_ERROR, errorMessage: "" });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchMiscSettingSagar() {
  yield takeLatest(ADD_SETTINGS, addSetting);
  yield takeLatest(GET_SETTINGS, getSettings);
  yield takeLatest(UPATE_SETTINGS, updateSettingById);
}
