export const primaryColor = "#091D3F";
export const white = "#FFF";

export const theme = {
  token: {
    colorPrimary: primaryColor,
    colorLink: primaryColor,
    fontFamily: "Inter",
    fontSize: 14,
    borderRadius: 0,
  },
  components: {
    Button: {
      borderRadius: 6,
      primaryShadow: "none",
    },
    Input: {
      borderRadius: 6,
      algorithm: true,
      controlHeight: 36,
      activeShadow: "none",
    },
    Form: {
      marginLG: 10,
      labelFontSize: 13,
      verticalLabelPadding: "0 0 6px",
    },
    Layout: {
      headerBg: primaryColor,
      headerPadding: "0 20px",
    },
    Segmented: {
      borderRadiusLG: 6,
      trackPadding: 6,
    },
    Table: {
      headerBg: white,
    },
    Dropdown: {
      borderRadiusLG: 8,
    },
    Menu: {
      algorithm: true,
      itemHoverBg: "#F1F5F9",
      itemSelectedBg: "#F1F5F9",
      itemBorderRadius: 8,
    },
    Modal: {
      titleFontSize: 18,
      colorBgMask: "rgba(255, 255, 255, 0.6)",
    },
    Select: {
      borderRadius: 6,
    },
  },
};
