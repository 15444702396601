import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_WEB_SCRAPING_STATUS,
  GET_ALL_WEB_SCRAPING_STATUS_LOADING,
  GET_ALL_WEB_SCRAPING_STATUS_SUCCESS,
  GET_ALL_WEB_SCRAPING_STATUS_ERROR,
} from "./actionTypes";
import { api } from "utils/api";

function* getScrapingStatus({ body }) {
  yield put({ type: GET_ALL_WEB_SCRAPING_STATUS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: `https://sf115ts0d0.execute-api.us-east-1.amazonaws.com/dev/data-metrics/last-scrape-status`,
      body,
    });
    yield put({
      type: GET_ALL_WEB_SCRAPING_STATUS_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: GET_ALL_WEB_SCRAPING_STATUS_ERROR, errorMessage: "" });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchStatusListSaga() {
  yield takeLatest(GET_ALL_WEB_SCRAPING_STATUS, getScrapingStatus);
}
