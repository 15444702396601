export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_LOADING = "GET_SETTINGS_LOADING";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";
export const GET_SETTINGS_CLEAR = "GET_SETTINGS_CLEAR";

export const ADD_SETTINGS = "ADD_SETTINGS";
export const ADD_SETTINGS_LOADING = "ADD_SETTINGS_LOADING";
export const ADD_SETTINGS_SUCCESS = "ADD_SETTINGS_SUCCESS";
export const ADD_SETTINGS_ERROR = "ADD_SETTINGS_ERROR";
export const ADD_SETTINGS_CLEAR = "ADD_SETTINGS_CLEAR"

export const UPATE_SETTINGS = "UPATE_SETTINGS";
export const UPATE_SETTINGS_LOADING = "UPATE_SETTINGS_LOADING";
export const UPATE_SETTINGS_SUCCESS = "UPATE_SETTINGS_SUCCESS";
export const UPATE_SETTINGS_ERROR = "UPATE_SETTINGS_ERROR";
export const UPATE_SETTINGS_CLEAR = "UPATE_SETTINGS_CLEAR";