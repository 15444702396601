import { combineReducers } from "redux";
import sharedReducer from "shared/reducer";
import dashboard from "screens/dashboard/reducer";
import authReducer from "screens/login/reducer";
import resultsReducer from "screens/results/reducer";
import aiSandboxReducer from "screens/aiSandbox/reducer";
import waitlistReducer from "screens/waitlist/reducer";
import miscSettingReducer from "screens/miscSetting/reducer";
import statusListReducer from "screens/statusList/reducer";
import userPermissionsReducer from "screens/userPermissions/reducer";
import profileReducer from "screens/profile/reducer";
import { LOGOUT } from "screens/login/actionTypes";

const appReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboard,
  shared: sharedReducer,
  results: resultsReducer,
  profile: profileReducer,
  waitlist: waitlistReducer,
  aiSandbox: aiSandboxReducer,
  statusList: statusListReducer,
  miscSetting: miscSettingReducer,
  userPermissions: userPermissionsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
