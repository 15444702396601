import { all, fork } from "redux-saga/effects";
import watchSharedSaga from "shared/saga";
import watchLoginSaga from "screens/login/saga";
import watchResultSaga from "screens/results/saga";
import watchProfileSaga from "screens/profile/saga";
import watchWaitlistSagar from "screens/waitlist/saga";
import watchMiscSettingSaga from "screens/miscSetting/saga";
import watchStatusListSaga from "screens/statusList/saga";
import watchAISandboxSaga from "screens/aiSandbox/saga";
import watchDashboardSaga from "screens/dashboard/saga";
import watchUserPermissionsSaga from "screens/userPermissions/saga";

export default function* root() {
   yield all([fork(watchLoginSaga)]);
   yield all([fork(watchResultSaga)]);
   yield all([fork(watchSharedSaga)]);
   yield all([fork(watchProfileSaga)]);
   yield all([fork(watchWaitlistSagar)]);
   yield all([fork(watchDashboardSaga)]);
   yield all([fork(watchAISandboxSaga)]);
   yield all([fork(watchStatusListSaga)]);
   yield all([fork(watchMiscSettingSaga)]);
   yield all([fork(watchUserPermissionsSaga)]);
}
