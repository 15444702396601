import { message } from "antd";
import { isObject } from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

export const errorhandling = (error) => {
  let errorMessage = "";
  if (error?.response?.data && isObject(error?.response?.data)) {
    if (error?.response?.data?.errors) {
      errorMessage = Object.keys(error?.response?.data?.errors)
        .map(
          (item) =>
            `${item}: ${error?.response?.data?.errors?.[item]?.join(", ")}`
        )
        .join(", ");
      message.error(errorMessage, 3);
    } else if (error?.response?.data?.detail || error?.response?.data?.title) {
      errorMessage = (
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        ""
      ).trim();
      message.error(errorMessage, 3);
    } else if (error?.response?.data) {
      message.error(error?.response?.data?.message, 3);
    }
  } else if (error?.response?.data) {
    errorMessage = error?.response?.data;
    message.error(errorMessage, 3);
  }
  return errorMessage;
};

export const generateUUID = () => uuidv4();

export const download = (blob, fileName) => {
  let link = document.createElement("a");
  if (link.download !== undefined) {
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const downloadJson = (data, filename) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const currencyToNumber = (currency) => {
  if (currency) {
    currency = currency.replace(/[^0-9.-]+/g, "");
    const number = parseFloat(currency);
    return number;
  }
  return 0;
};

export const numberToCurrency = (number = 0) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat("en-US").format(number);
};

export const getFormattedDateUTC = (date) => moment.utc(date).format();

const convertBinaryToBlob = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const downloadExcel = (data, columns, fileName) => {
  const rows = [];
  data.forEach((record) => {
    const row = {};
    columns.forEach((item) => {
      row[item.title] = item.render ? item.render(record) : record[item.key];
    });
    rows.push(row);
  });
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const blob = new Blob([convertBinaryToBlob(wbout)], {
    type: "application/octet-stream",
  });
  download(blob, `${fileName}.xlsx`);
  return blob;
};
