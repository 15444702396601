export const GET_ALL_WEB_SCRAPING_WAITLIST = "GET_ALL_WEB_SCRAPING_WAITLIST";
export const GET_ALL_WEB_SCRAPING_WAITLIST_LOADING = "GET_ALL_WEB_SCRAPING_WAITLIST_LOADING";
export const GET_ALL_WEB_SCRAPING_WAITLIST_SUCCESS = "GET_ALL_WEB_SCRAPING_WAITLIST_SUCCESS";
export const GET_ALL_WEB_SCRAPING_WAITLIST_ERROR = "GET_ALL_WEB_SCRAPING_WAITLIST_ERROR";
export const GET_ALL_WEB_SCRAPING_WAITLIST_CLEAR = "GET_ALL_WEB_SCRAPING_WAITLIST_CLEAR";

export const ADD_WEB_SCRAPING_WAITLIST = "ADD_WEB_SCRAPING_WAITLIST";
export const ADD_WEB_SCRAPING_WAITLIST_LOADING = "ADD_WEB_SCRAPING_WAITLIST_LOADING";
export const ADD_WEB_SCRAPING_WAITLIST_SUCCESS = "ADD_WEB_SCRAPING_WAITLIST_SUCCESS";
export const ADD_WEB_SCRAPING_WAITLIST_ERROR = "ADD_WEB_SCRAPING_WAITLIST_ERROR";
export const ADD_WEB_SCRAPING_WAITLIST_CLEAR = "ADD_WEB_SCRAPING_WAITLIST_CLEAR"

export const UPDATE_WEB_SCRAPING_WAITLIST_BY_ID = "UPDATE_WEB_SCRAPING_WAITLIST_BY_ID";
export const UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING = "UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING";
export const UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS = "UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS";
export const UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR = "UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR";
export const UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR = "UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR";

export const DELETE_WEB_SCRAPING_WAITLIST_BY_ID = "DELETE_WEB_SCRAPING_WAITLIST_BY_ID";
export const DELETE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING = "DELETE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING";
export const DELETE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS = "DELETE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS";
export const DELETE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR = "DELETE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR";
export const DELETE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR = "DELETE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR";

