import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_CURRENT_USER_BY_ID,
  GET_CURRENT_USER_BY_ID_LOADING,
  GET_CURRENT_USER_BY_ID_SUCCESS,
  GET_CURRENT_USER_BY_ID_ERROR,
} from "./actionTypes";
import { api } from "utils/api";
import { errorhandling } from "utils/helper";

function* geCurrentUserById({ body }) {
  yield put({ type: GET_CURRENT_USER_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: "https://6824mjxh23.execute-api.us-east-1.amazonaws.com/dev/id/{id}",
      body,
    });
    yield put({ type: GET_CURRENT_USER_BY_ID_SUCCESS, data: data });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({
      type: GET_CURRENT_USER_BY_ID_ERROR,
      errorMessage: errorMessage,
    });
  }
}

export default function* watchProfileSaga() {
  yield takeLatest(GET_CURRENT_USER_BY_ID, geCurrentUserById);
}
