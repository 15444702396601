/**
 * Root saga manages watcher lifecycle
 */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  SEND_CONFIRMATION_CODE,
  SEND_CONFIRMATION_CODE_LOADING,
  SEND_CONFIRMATION_CODE_SUCCESS,
  SEND_CONFIRMATION_CODE_ERROR,
  RESEND_CONFIRMATION_CODE,
  RESEND_CONFIRMATION_CODE_LOADING,
  RESEND_CONFIRMATION_CODE_SUCCESS,
  RESEND_CONFIRMATION_CODE_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_CONFIRMATION_CODE,
  VERIFY_CONFIRMATION_CODE_ERROR,
  VERIFY_CONFIRMATION_CODE_LOADING,
  VERIFY_CONFIRMATION_CODE_SUCCESS,
} from "./actionTypes";

import { api } from "utils/api";
import { baseURL, env } from "utils/config";

function* verifyConfirmationCode({ body }) {
  yield put({ type: VERIFY_CONFIRMATION_CODE_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `${baseURL}${env}/confirm/code`,
      body,
    });
    yield put({
      type: VERIFY_CONFIRMATION_CODE_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({ type: VERIFY_CONFIRMATION_CODE_ERROR, errorMessage: "" });
  }
}

function* sendConfirmationCode({ body }) {
  yield put({ type: SEND_CONFIRMATION_CODE_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `${baseURL}${env}/confirm/code/send`,
      body,
    });
    yield put({
      type: SEND_CONFIRMATION_CODE_SUCCESS,
      data: true,
    });
  } catch (error) {
    yield put({ type: SEND_CONFIRMATION_CODE_ERROR, errorMessage: "" });
  }
}

function* resendConfirmationCode({ body }) {
  const { username, password } = body;
  yield put({ type: RESEND_CONFIRMATION_CODE_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `${baseURL}${env}/confirm/code/resend`,
      body,
    });
    yield put({
      type: RESEND_CONFIRMATION_CODE_SUCCESS,
      data: data?.newPasswordRequired,
    });
  } catch (error) {
    yield put({ type: RESEND_CONFIRMATION_CODE_ERROR, errorMessage: "" });
  }
}

function* resetPassword({ body }) {
  yield put({ type: RESET_PASSWORD_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `${baseURL}${env}/confirm/code`,
      body,
    });
    yield put({
      type: RESET_PASSWORD_SUCCESS,
    });
  } catch (error) {
    yield put({ type: RESET_PASSWORD_ERROR, errorMessage: "" });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchSharedSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(SEND_CONFIRMATION_CODE, sendConfirmationCode);
  yield takeLatest(RESEND_CONFIRMATION_CODE, resendConfirmationCode);
  yield takeLatest(VERIFY_CONFIRMATION_CODE, verifyConfirmationCode);
}
