import { combineReducers } from "redux";
import {
  ADD_NOTE_LOADING,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_ERROR,
  ADD_NOTE_CLEAR,
  GET_ALL_RESULTS_LOADING,
  GET_ALL_RESULTS_SUCCESS,
  GET_ALL_RESULTS_ERROR,
  GET_ALL_RESULTS_CLEAR,
  GET_RESULT_BY_RESULT_ID_LOADING,
  GET_RESULT_BY_RESULT_ID_SUCCESS,
  GET_RESULT_BY_RESULT_ID_ERROR,
  GET_ALL_CPP_PROFILES_LOADING,
  GET_ALL_CPP_PROFILES_SUCCESS,
  GET_ALL_CPP_PROFILES_ERROR,
  GET_ALL_CPP_PROFILES_CLEAR,
  GET_CPP_PROFILE_BY_ID_LOADING,
  GET_CPP_PROFILE_BY_ID_SUCCESS,
  GET_CPP_PROFILE_BY_ID_ERROR,
  GET_CPP_PROFILE_BY_ID_CLEAR,
  UPDATE_CPP_PROFILE_BY_ID_LOADING,
  UPDATE_CPP_PROFILE_BY_ID_SUCCESS,
  UPDATE_CPP_PROFILE_BY_ID_ERROR,
  UPDATE_CPP_PROFILE_BY_ID_CLEAR,
  GET_REVIEW_CPP_PROFILE_BY_ID_LOADING,
  GET_REVIEW_CPP_PROFILE_BY_ID_SUCCESS,
  GET_REVIEW_CPP_PROFILE_BY_ID_ERROR,
  GET_REVIEW_CPP_PROFILE_BY_ID_CLEAR,
  GET_DATA_SOURCE_PROJECT_BY_ID_LOADING,
  GET_DATA_SOURCE_PROJECT_BY_ID_SUCCESS,
  GET_DATA_SOURCE_PROJECT_BY_ID_ERROR,
  GET_DATA_SOURCE_PROJECT_BY_ID_CLEAR,
  COMBINE_PROFILE_LOADING,
  COMBINE_PROFILE_SUCCESS,
  COMBINE_PROFILE_ERROR,
  COMBINE_PROFILE_CLEAR,
  GET_CHAT_SEARCH_RESULTS_LOADING,
  GET_CHAT_SEARCH_RESULTS_SUCCESS,
  GET_CHAT_SEARCH_RESULTS_ERROR,
  GET_CHAT_DISCOVERY_RESULTS_LOADING,
  GET_CHAT_DISCOVERY_RESULTS_SUCCESS,
  GET_CHAT_DISCOVERY_RESULTS_ERROR,
} from "./actionTypes";

const results = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_ALL_RESULTS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_ALL_RESULTS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_ALL_RESULTS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case GET_ALL_RESULTS_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const resultByResultId = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_RESULT_BY_RESULT_ID_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_RESULT_BY_RESULT_ID_SUCCESS: {
      return {
        loading: false,
        data: {
          ...state.data,
          [action.data.resultId]: action.data,
        },
        error: "",
      };
    }
    case GET_RESULT_BY_RESULT_ID_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const cppProfiles = (
  state = { loading: false, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case GET_ALL_CPP_PROFILES_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_ALL_CPP_PROFILES_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_ALL_CPP_PROFILES_ERROR: {
      return {
        loading: false,
        data: [],
        error: action.errorMessage,
      };
    }
    case GET_ALL_CPP_PROFILES_CLEAR: {
      return {
        loading: false,
        data: [],
        error: "",
      };
    }
    default:
      return state;
  }
};

const cppProfileById = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_CPP_PROFILE_BY_ID_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_CPP_PROFILE_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: {
          ...state.data,
          [action.data.uuid]: action.data,
        },
        error: "",
      };
    }
    case GET_CPP_PROFILE_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case GET_CPP_PROFILE_BY_ID_CLEAR: {
      return {
        loading: false,
        data: {},
        error: false,
      };
    }
    default:
      return state;
  }
};

const modifyCPPProfileById = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_CPP_PROFILE_BY_ID_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case UPDATE_CPP_PROFILE_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case UPDATE_CPP_PROFILE_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case UPDATE_CPP_PROFILE_BY_ID_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const reviewCPPProfileById = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_REVIEW_CPP_PROFILE_BY_ID_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_REVIEW_CPP_PROFILE_BY_ID_SUCCESS: {
      return {
        loading: false,
          data: action.data,
        error: "",
      };
    }
    case GET_REVIEW_CPP_PROFILE_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case GET_REVIEW_CPP_PROFILE_BY_ID_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const dataSourceProjectById = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_DATA_SOURCE_PROJECT_BY_ID_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_DATA_SOURCE_PROJECT_BY_ID_SUCCESS: {
      const { data, profileId } = action.data;
      return {
        loading: false,
        data: {
          ...state.data,
          [profileId]: {
            ...state.data[profileId],
            [data.uuid]: data,
          },
        },
        error: "",
      };
    }
    case GET_DATA_SOURCE_PROJECT_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case GET_DATA_SOURCE_PROJECT_BY_ID_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const createNote = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case ADD_NOTE_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case ADD_NOTE_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case ADD_NOTE_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case ADD_NOTE_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};


const profileCombined = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case COMBINE_PROFILE_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case COMBINE_PROFILE_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case COMBINE_PROFILE_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case COMBINE_PROFILE_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const chatSearchResults = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_CHAT_SEARCH_RESULTS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_CHAT_SEARCH_RESULTS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_CHAT_SEARCH_RESULTS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const chatDiscoveryResults = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_CHAT_DISCOVERY_RESULTS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_CHAT_DISCOVERY_RESULTS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_CHAT_DISCOVERY_RESULTS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  results,
  createNote,
  cppProfiles,
  cppProfileById,
  profileCombined,
  resultByResultId,
  chatSearchResults,
  reviewCPPProfileById,
  modifyCPPProfileById,
  chatDiscoveryResults,
  dataSourceProjectById,
});
