export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_LOADING = "CREATE_USER_LOADING";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const GET_USERS  = "GET_USERS";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const UPDATE_USER_BY_ID  = "UPDATE_USER_BY_ID";
export const UPDATE_USER_BY_ID_LOADING = "UPDATE_USER_BY_ID_LOADING";
export const UPDATE_USER_BY_ID_SUCCESS = "UPDATE_USER_BY_ID_SUCCESS";
export const UPDATE_USER_BY_ID_ERROR = "UPDATE_USER_BY_ID_ERROR";

export const GET_USER_BY_ID  = "GET_USER_BY_ID";
export const GET_USER_BY_ID_LOADING = "GET_USER_BY_ID_LOADING";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_ERROR = "GET_USER_BY_ID_ERROR";