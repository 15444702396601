export const LOGIN = "LOGIN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_CLEAR = "LOGIN_CLEAR";

export const LOGOUT = "LOGOUT";

export const CHECK_PASSWORD_RESET_REQUIRED = "CHECK_PASSWORD_RESET_REQUIRED";
export const CHECK_PASSWORD_RESET_REQUIRED_LOADING =
  "CHECK_PASSWORD_RESET_REQUIRED_LOADING";
export const CHECK_PASSWORD_RESET_REQUIRED_SUCCESS =
  "CHECK_PASSWORD_RESET_REQUIRED_SUCCESS";
export const CHECK_PASSWORD_RESET_REQUIRED_ERROR =
  "CHECK_PASSWORD_RESET_REQUIRED_ERROR";
