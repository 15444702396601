import { combineReducers } from "redux";
import {
  GET_ALL_WEB_SCRAPING_STATUS_LOADING,
  GET_ALL_WEB_SCRAPING_STATUS_SUCCESS,
  GET_ALL_WEB_SCRAPING_STATUS_ERROR,
  GET_ALL_WEB_SCRAPING_STATUS_CLEAR,
} from "./actionTypes";

const status = (state = { loading: false, data: [], error: "" }, action) => {
  switch (action.type) {
    case GET_ALL_WEB_SCRAPING_STATUS_LOADING: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case GET_ALL_WEB_SCRAPING_STATUS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_ALL_WEB_SCRAPING_STATUS_ERROR: {
      return {
        loading: false,
        data: [],
        error: action.errorMessage,
      };
    }
    case GET_ALL_WEB_SCRAPING_STATUS_CLEAR: {
      return {
        loading: false,
        data: [],
        error: "",
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  status,
});
