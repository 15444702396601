import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_CONFIGURATION,
  CREATE_CONFIGURATION_LOADING,
  CREATE_CONFIGURATION_SUCCESS,
  CREATE_CONFIGURATION_ERROR,
  GET_CONFIGURATIONS,
  GET_CONFIGURATION_LOADING,
  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_ERROR,
  UPDATE_CONFIGURATION,
  UPDATE_CONFIGURATION_LOADING,
  UPDATE_CONFIGURATION_SUCCESS,
  UPDATE_CONFIGURATION_ERROR,
  GET_TEST_DATA_BY_ID,
  GET_TEST_DATA_BY_ID_LOADING,
  GET_TEST_DATA_BY_ID_SUCCESS,
  GET_TEST_DATA_BY_ID_ERROR,
  UPLOAD_FILE_TO_S3,
  UPLOAD_FILE_TO_S3_LOADING,
  UPLOAD_FILE_TO_S3_SUCCESS,
  UPLOAD_FILE_TO_S3_ERROR,
  DOWNLOAD_FILE_FROM_S3,
  DOWNLOAD_FILE_FROM_S3_LOADING,
  DOWNLOAD_FILE_FROM_S3_SUCCESS,
  DOWNLOAD_FILE_FROM_S3_ERROR,
  EXECUTE_PROMPT_BY_ID,
  EXECUTE_PROMPT_BY_ID_LOADING,
  EXECUTE_PROMPT_BY_ID_SUCCESS,
  EXECUTE_PROMPT_BY_ID_ERROR,
} from "./actionTypes";
import { api } from "utils/api";
import { errorhandling, generateUUID } from "utils/helper";

function* getTestDataById({ body }) {
  const { selectedConfiguration } = body;
  yield put({ type: GET_TEST_DATA_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: "https://boiuzarifi.execute-api.us-east-1.amazonaws.com/dev/datalake/opensearch/data_sources/{id}",
      body,
    });
    yield put({ type: GET_TEST_DATA_BY_ID_SUCCESS, data: data });
    yield put({
      type: UPLOAD_FILE_TO_S3,
      body: { selectedConfiguration, testData: data },
    });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: GET_TEST_DATA_BY_ID_ERROR, errorMessage: errorMessage });
  }
}

function* uploadFileToS3({ body }) {
  const { selectedConfiguration, testData } = body;
  const id = generateUUID();
  const key = `${selectedConfiguration.name}/test-data/${id}.json`;
  yield put({ type: UPLOAD_FILE_TO_S3_LOADING });
  try {
    yield call(api, {
      method: "POST",
      url: "https://5uxyvb3iak.execute-api.us-east-1.amazonaws.com/dev/upload-file",
      body: {
        key: key,
        body: JSON.stringify(testData),
      },
    });
    yield put({ type: UPLOAD_FILE_TO_S3_SUCCESS });
    yield put({
      type: UPDATE_CONFIGURATION,
      body: {
        ...selectedConfiguration,
        testData: [...selectedConfiguration.testData, { filePath: key, id }],
      },
    });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: UPLOAD_FILE_TO_S3_ERROR, errorMessage: errorMessage });
  }
}

function* getConfigurations({ body }) {
  yield put({ type: GET_CONFIGURATION_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: "https://5uxyvb3iak.execute-api.us-east-1.amazonaws.com/dev/",
      body,
    });
    yield put({ type: GET_CONFIGURATION_SUCCESS, data: data });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: GET_CONFIGURATION_ERROR, errorMessage: errorMessage });
  }
}

function* createConfiguration({ body }) {
  yield put({ type: CREATE_CONFIGURATION_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: "https://5uxyvb3iak.execute-api.us-east-1.amazonaws.com/dev/",
      body,
    });
    yield put({ type: CREATE_CONFIGURATION_SUCCESS, data: data });
    yield put({ type: GET_CONFIGURATIONS });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: CREATE_CONFIGURATION_ERROR, errorMessage: errorMessage });
  }
}

function* updateConfiguration({ body }) {
  yield put({ type: UPDATE_CONFIGURATION_LOADING });
  try {
    const { data } = yield call(api, {
      method: "PUT",
      url: "https://5uxyvb3iak.execute-api.us-east-1.amazonaws.com/dev/id/{id}",
      body,
    });
    yield put({ type: UPDATE_CONFIGURATION_SUCCESS, data: data });
    yield put({ type: GET_CONFIGURATIONS });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: UPDATE_CONFIGURATION_ERROR, errorMessage: errorMessage });
  }
}

function* downloadFileFromS3({ body }) {
  const { key, id } = body;
  yield put({ type: DOWNLOAD_FILE_FROM_S3_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: "https://5uxyvb3iak.execute-api.us-east-1.amazonaws.com/dev/download-file",
      body: { key },
    });
    yield put({
      type: DOWNLOAD_FILE_FROM_S3_SUCCESS,
      data: { id, data },
    });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({
      type: DOWNLOAD_FILE_FROM_S3_ERROR,
      errorMessage: errorMessage,
    });
  }
}

function* executePromptById({ body }) {
  yield put({ type: EXECUTE_PROMPT_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: "https://5uxyvb3iak.execute-api.us-east-1.amazonaws.com/dev/id/{id}/prompt/{promptId}/execute",
      body,
    });
    yield put({
      type: EXECUTE_PROMPT_BY_ID_SUCCESS,
      data: data,
    });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({
      type: EXECUTE_PROMPT_BY_ID_ERROR,
      errorMessage: errorMessage,
    });
  }
}

export default function* watchAISandboxSaga() {
  yield takeLatest(UPLOAD_FILE_TO_S3, uploadFileToS3);
  yield takeLatest(GET_TEST_DATA_BY_ID, getTestDataById);
  yield takeLatest(GET_CONFIGURATIONS, getConfigurations);
  yield takeLatest(EXECUTE_PROMPT_BY_ID, executePromptById);
  yield takeEvery(DOWNLOAD_FILE_FROM_S3, downloadFileFromS3);
  yield takeLatest(CREATE_CONFIGURATION, createConfiguration);
  yield takeLatest(UPDATE_CONFIGURATION, updateConfiguration);
}
