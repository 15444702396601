import Public from "layouts/public/loadable";
import Private from "layouts/private/loadable";
import { ConfigProvider } from "antd";
import { theme } from "assets/theme/theme";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const Navigation = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { tokens } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isEmpty(tokens.data)) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [tokens]);

  return (
    <ConfigProvider theme={theme}>
      {isAuthenticated ? <Private /> : <Public />}
    </ConfigProvider>
  );
};

export default Navigation;
