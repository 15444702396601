import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOGIN,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CHECK_PASSWORD_RESET_REQUIRED,
  CHECK_PASSWORD_RESET_REQUIRED_LOADING,
  CHECK_PASSWORD_RESET_REQUIRED_SUCCESS,
  CHECK_PASSWORD_RESET_REQUIRED_ERROR,
} from "./actionTypes";
import { api } from "utils/api";
import { baseURL, env } from "utils/config";
import { errorhandling } from "utils/helper";

function* login({ body }) {
  yield put({ type: LOGIN_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `${baseURL}${env}/login`,
      body,
    });
    yield put({
      type: LOGIN_SUCCESS,
      data: data,
    });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: LOGIN_ERROR, errorMessage: errorMessage });
  }
}

function* checkIfPasswordResetRequired({ body }) {
  const { username, password } = body;
  yield put({ type: CHECK_PASSWORD_RESET_REQUIRED_LOADING });
  try {
    const { data } = yield call(api, {
      method: "POST",
      url: `${baseURL}${env}/new/password`,
      body,
    });
    if (!data?.newPasswordRequired) {
      yield put({
        type: LOGIN,
        body: { username, password },
      });
    }
    yield put({
      type: CHECK_PASSWORD_RESET_REQUIRED_SUCCESS,
      data: data?.newPasswordRequired,
    });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({
      type: CHECK_PASSWORD_RESET_REQUIRED_ERROR,
      errorMessage: errorMessage,
    });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchLoginSaga() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(CHECK_PASSWORD_RESET_REQUIRED, checkIfPasswordResetRequired);
}
