import { combineReducers } from "redux";
import {
  GET_ALL_WEB_SCRAPING_WAITLIST_LOADING,
  GET_ALL_WEB_SCRAPING_WAITLIST_SUCCESS,
  GET_ALL_WEB_SCRAPING_WAITLIST_ERROR,
  ADD_WEB_SCRAPING_WAITLIST_LOADING,
  ADD_WEB_SCRAPING_WAITLIST_SUCCESS,
  ADD_WEB_SCRAPING_WAITLIST_ERROR,
  ADD_WEB_SCRAPING_WAITLIST_CLEAR,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR,
  UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR,
  DELETE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR,
} from "./actionTypes";

const waitlist = (state = { loading: false, data: {}, error: "" }, action) => {
  switch (action.type) {
    case GET_ALL_WEB_SCRAPING_WAITLIST_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_ALL_WEB_SCRAPING_WAITLIST_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_ALL_WEB_SCRAPING_WAITLIST_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const waitlistAdded = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case ADD_WEB_SCRAPING_WAITLIST_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case ADD_WEB_SCRAPING_WAITLIST_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case ADD_WEB_SCRAPING_WAITLIST_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case ADD_WEB_SCRAPING_WAITLIST_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const waitlistUpdated = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case UPDATE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

const waitlistDeleted = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_WEB_SCRAPING_WAITLIST_BY_ID_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case DELETE_WEB_SCRAPING_WAITLIST_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case DELETE_WEB_SCRAPING_WAITLIST_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case DELETE_WEB_SCRAPING_WAITLIST_BY_ID_CLEAR: {
      return {
        loading: false,
        data: {},
        error: "",
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  waitlist,
  waitlistAdded,
  waitlistUpdated,
  waitlistDeleted,
});
