import { combineReducers } from "redux";
import {
  LOGIN_CLEAR,
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  CHECK_PASSWORD_RESET_REQUIRED_LOADING,
  CHECK_PASSWORD_RESET_REQUIRED_SUCCESS,
  CHECK_PASSWORD_RESET_REQUIRED_ERROR,
} from "./actionTypes";

const tokens = (state = { loading: false, data: {}, error: "" }, action) => {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        loading: true,
        data: {},
        error: false,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        loading: false,
        data: action.data?.tokens,
        error: false,
      };
    }
    case LOGIN_ERROR: {
      return {
        loading: false,
        data: {},
        error: true,
      };
    }
    case LOGIN_CLEAR: {
      return { loading: false, data: {}, error: false };
    }
    default:
      return state;
  }
};

const loggedInUserId = (
  state = { loading: false, data: "", error: "" },
  action
) => {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        loading: true,
        data: "",
        error: "",
      };
    }
    case LOGIN_SUCCESS: {
      return {
        loading: false,
        data: action.data?.user?.id,
        error: "",
      };
    }
    case LOGIN_ERROR: {
      return {
        loading: false,
        data: "",
        error: action.errorMessage,
      };
    }
    case LOGIN_CLEAR: {
      return { loading: false, data: "", error: false };
    }
    default:
      return state;
  }
};

const isPasswordResetRequired = (
  state = { loading: false, data: false, error: "" },
  action
) => {
  switch (action.type) {
    case CHECK_PASSWORD_RESET_REQUIRED_LOADING: {
      return {
        loading: true,
        data: false,
        error: "",
      };
    }
    case CHECK_PASSWORD_RESET_REQUIRED_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: false,
      };
    }
    case CHECK_PASSWORD_RESET_REQUIRED_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  tokens,
  loggedInUserId,
  isPasswordResetRequired,
});
