export const RESEND_CONFIRMATION_CODE = "RESEND_CONFIRMATION_CODE";
export const RESEND_CONFIRMATION_CODE_LOADING =
  "RESEND_CONFIRMATION_CODE_LOADING";
export const RESEND_CONFIRMATION_CODE_SUCCESS =
  "RESEND_CONFIRMATION_CODE_SUCCESS";
export const RESEND_CONFIRMATION_CODE_ERROR = "RESEND_CONFIRMATION_CODE_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_CLEAR = "RESET_PASSWORD_CLEAR";

export const SEND_CONFIRMATION_CODE = "SEND_CONFIRMATION_CODE";
export const SEND_CONFIRMATION_CODE_LOADING = "SEND_CONFIRMATION_CODE_LOADING";
export const SEND_CONFIRMATION_CODE_SUCCESS = "SEND_CONFIRMATION_CODE_SUCCESS";
export const SEND_CONFIRMATION_CODE_ERROR = "SEND_CONFIRMATION_CODE_ERROR";
export const SEND_CONFIRMATION_CODE_CLEAR = "SEND_CONFIRMATION_CODE_CLEAR";

export const VERIFY_CONFIRMATION_CODE = "VERIFY_CONFIRMATION_CODE";
export const VERIFY_CONFIRMATION_CODE_LOADING =
  "VERIFY_CONFIRMATION_CODE_LOADING";
export const VERIFY_CONFIRMATION_CODE_SUCCESS =
  "VERIFY_CONFIRMATION_CODE_SUCCESS";
export const VERIFY_CONFIRMATION_CODE_ERROR = "VERIFY_CONFIRMATION_CODE_ERROR";
