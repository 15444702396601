export const GET_ALL_RESULTS = "GET_ALL_RESULTS";
export const GET_ALL_RESULTS_LOADING = "GET_ALL_RESULTS_LOADING";
export const GET_ALL_RESULTS_SUCCESS = "GET_ALL_RESULTS_SUCCESS";
export const GET_ALL_RESULTS_ERROR = "GET_ALL_RESULTS_ERROR";
export const GET_ALL_RESULTS_CLEAR = "GET_ALL_RESULTS_CLEAR";

export const CREATE_RESULT = "CREATE_RESULT";
export const CREATE_RESULT_LOADING = "CREATE_RESULT_LOADING";
export const CREATE_RESULT_SUCCESS = "CREATE_RESULT_SUCCESS";
export const CREATE_RESULT_ERROR = "CREATE_RESULT_ERROR";
export const CREATE_RESULT_CLEAR = "CREATE_RESULT_CLEAR"

export const GET_RESULT_BY_RESULT_ID = "GET_RESULT_BY_RESULT_ID";
export const GET_RESULT_BY_RESULT_ID_LOADING = "GET_RESULT_BY_RESULT_ID_LOADING";
export const GET_RESULT_BY_RESULT_ID_SUCCESS = "GET_RESULT_BY_RESULT_ID_SUCCESS";
export const GET_RESULT_BY_RESULT_ID_ERROR = "GET_RESULT_BY_RESULT_ID_ERROR";
export const GET_RESULT_BY_RESULT_ID_CLEAR = "GET_RESULT_BY_RESULT_ID_CLEAR";

export const UPATE_RESULT_BY_ID = "UPATE_RESULT_BY_ID";
export const UPATE_RESULT_BY_ID_LOADING = "UPATE_RESULT_BY_ID_LOADING";
export const UPATE_RESULT_BY_ID_SUCCESS = "UPATE_RESULT_BY_ID_SUCCESS";
export const UPATE_RESULT_BY_ID_ERROR = "UPATE_RESULT_BY_ID_ERROR";
export const UPATE_RESULT_BY_ID_CLEAR = "UPATE_RESULT_BY_ID_CLEAR";

export const GET_ALL_CPP_PROFILES = "GET_ALL_CPP_PROFILES";
export const GET_ALL_CPP_PROFILES_LOADING = "GET_ALL_CPP_PROFILES_LOADING";
export const GET_ALL_CPP_PROFILES_SUCCESS = "GET_ALL_CPP_PROFILES_SUCCESS";
export const GET_ALL_CPP_PROFILES_ERROR = "GET_ALL_CPP_PROFILES_ERROR";
export const GET_ALL_CPP_PROFILES_CLEAR = "GET_ALL_CPP_PROFILES_CLEAR";

export const GET_CPP_PROFILE_BY_ID = "GET_CPP_PROFILE_BY_ID";
export const GET_CPP_PROFILE_BY_ID_LOADING = "GET_CPP_PROFILE_BY_ID_LOADING";
export const GET_CPP_PROFILE_BY_ID_SUCCESS = "GET_CPP_PROFILE_BY_ID_SUCCESS";
export const GET_CPP_PROFILE_BY_ID_ERROR = "GET_CPP_PROFILE_BY_ID_ERROR";
export const GET_CPP_PROFILE_BY_ID_CLEAR = "GET_CPP_PROFILE_BY_ID_CLEAR";

export const UPDATE_CPP_PROFILE_BY_ID = "UPDATE_CPP_PROFILE_BY_ID";
export const UPDATE_CPP_PROFILE_BY_ID_LOADING = "UPDATE_CPP_PROFILE_BY_ID_LOADING";
export const UPDATE_CPP_PROFILE_BY_ID_SUCCESS = "UPDATE_CPP_PROFILE_BY_ID_SUCCESS";
export const UPDATE_CPP_PROFILE_BY_ID_ERROR = "UPDATE_CPP_PROFILE_BY_ID_ERROR";
export const UPDATE_CPP_PROFILE_BY_ID_CLEAR = "UPDATE_CPP_PROFILE_BY_ID_CLEAR";

export const GET_REVIEW_CPP_PROFILE_BY_ID = "GET_REVIEW_CPP_PROFILE_BY_ID";
export const GET_REVIEW_CPP_PROFILE_BY_ID_LOADING = "GET_REVIEW_CPP_PROFILE_BY_ID_LOADING";
export const GET_REVIEW_CPP_PROFILE_BY_ID_SUCCESS = "GET_REVIEW_CPP_PROFILE_BY_ID_SUCCESS";
export const GET_REVIEW_CPP_PROFILE_BY_ID_ERROR = "GET_REVIEW_CPP_PROFILE_BY_ID_ERROR";
export const GET_REVIEW_CPP_PROFILE_BY_ID_CLEAR = "GET_REVIEW_CPP_PROFILE_BY_ID_CLEAR";

export const GET_DATA_SOURCE_PROJECT_BY_ID = "GET_DATA_SOURCE_PROJECT_BY_ID";
export const GET_DATA_SOURCE_PROJECT_BY_ID_LOADING = "GET_DATA_SOURCE_PROJECT_BY_ID_LOADING";
export const GET_DATA_SOURCE_PROJECT_BY_ID_SUCCESS = "GET_DATA_SOURCE_PROJECT_BY_ID_SUCCESS";
export const GET_DATA_SOURCE_PROJECT_BY_ID_ERROR = "GET_DATA_SOURCE_PROJECT_BY_ID_ERROR";
export const GET_DATA_SOURCE_PROJECT_BY_ID_CLEAR = "GET_DATA_SOURCE_PROJECT_BY_ID_CLEAR";

export const ADD_NOTE = "ADD_NOTE";
export const ADD_NOTE_LOADING = "ADD_NOTE_LOADING";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_ERROR = "ADD_NOTE_ERROR";
export const ADD_NOTE_CLEAR = "ADD_NOTE_CLEAR";

export const COMBINE_PROFILE = "COMBINE_PROFILE";
export const COMBINE_PROFILE_LOADING = "COMBINE_PROFILE_LOADING";
export const COMBINE_PROFILE_SUCCESS = "COMBINE_PROFILE_SUCCESS";
export const COMBINE_PROFILE_ERROR = "COMBINE_PROFILE_ERROR";
export const COMBINE_PROFILE_CLEAR = "COMBINE_PROFILE_CLEAR";

export const GET_CHAT_SEARCH_RESULTS = "GET_CHAT_SEARCH_RESULTS";
export const GET_CHAT_SEARCH_RESULTS_LOADING = "GET_CHAT_SEARCH_RESULTS_LOADING";
export const GET_CHAT_SEARCH_RESULTS_SUCCESS = "GET_CHAT_SEARCH_RESULTS_SUCCESS";
export const GET_CHAT_SEARCH_RESULTS_ERROR = "GET_CHAT_SEARCH_RESULTS_ERROR";
export const GET_CHAT_SEARCH_RESULTS_CLEAR = "GET_CHAT_SEARCH_RESULTS_CLEAR";

export const GET_CHAT_DISCOVERY_RESULTS = "GET_CHAT_DISCOVERY_RESULTS";
export const GET_CHAT_DISCOVERY_RESULTS_LOADING = "GET_CHAT_DISCOVERY_RESULTS_LOADING";
export const GET_CHAT_DISCOVERY_RESULTS_SUCCESS = "GET_CHAT_DISCOVERY_RESULTS_SUCCESS";
export const GET_CHAT_DISCOVERY_RESULTS_ERROR = "GET_CHAT_DISCOVERY_RESULTS_ERROR";
export const GET_CHAT_DISCOVERY_RESULTS_CLEAR = "GET_CHAT_DISCOVERY_RESULTS_CLEAR";

export const DELETE_DATA_SOURCE_PROJECT_BY_ID = "DELETE_DATA_SOURCE_PROJECT_BY_ID";
export const DELETE_DATA_SOURCE_PROJECT_BY_ID_LOADING = "DELETE_DATA_SOURCE_PROJECT_BY_ID_LOADING";
export const DELETE_DATA_SOURCE_PROJECT_BY_ID_SUCCESS = "DELETE_DATA_SOURCE_PROJECT_BY_ID_SUCCESS";
export const DELETE_DATA_SOURCE_PROJECT_BY_ID_ERROR = "DELETE_DATA_SOURCE_PROJECT_BY_ID_ERROR";
export const DELETE_DATA_SOURCE_PROJECT_BY_ID_CLEAR = "DELETE_DATA_SOURCE_PROJECT_BY_ID_CLEAR";