import { combineReducers } from "redux";
import {
  GET_DATA_METRICS_LOADING,
  GET_DATA_METRICS_SUCCESS,
  GET_DATA_METRICS_ERROR,
  GET_DATA_METRICS_CLEAR,
} from "./actionTypes";


const dataMetrics = (
    state = { loading: false, data: {}, error: "" },
    action
  ) => {
    switch (action.type) {
      case GET_DATA_METRICS_LOADING: {
        return {
          loading: true,
          data: {},
          error: "",
        };
      }
      case GET_DATA_METRICS_SUCCESS: {
        return {
          loading: false,
          data: action.data,
          error: "",
        };
      }
      case GET_DATA_METRICS_ERROR: {
        return {
          loading: false,
          data: {},
          error: action.errorMessage,
        };
      }
      case GET_DATA_METRICS_CLEAR: {
        return {
          loading: false,
          data: {},
          error: "",
        };
      }
      default:
        return state;
    }
  };

  export default combineReducers({
    dataMetrics
  });
  