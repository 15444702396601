import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_USER,
  CREATE_USER_LOADING,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USER_BY_ID,
  GET_USER_BY_ID_LOADING,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  GET_USERS,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  UPDATE_USER_BY_ID,
  UPDATE_USER_BY_ID_LOADING,
  UPDATE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_ERROR,
} from "./actionTypes";
import { api } from "utils/api";
import { errorhandling } from "utils/helper";

function* getUsers({ body }) {
  yield put({ type: GET_USERS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: "https://6824mjxh23.execute-api.us-east-1.amazonaws.com/dev/",
      body,
    });
    yield put({ type: GET_USERS_SUCCESS, data: data });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: GET_USERS_ERROR, errorMessage: errorMessage });
  }
}

function* createUser({ body }) {
  yield put({ type: CREATE_USER_LOADING });
  try {
    yield call(api, {
      method: "POST",
      url: "https://6824mjxh23.execute-api.us-east-1.amazonaws.com/dev/",
      body,
    });
    yield put({ type: CREATE_USER_SUCCESS, data: true });
    yield put({ type: GET_USERS });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: CREATE_USER_ERROR, errorMessage: errorMessage });
  }
}

function* updateUserById({ body }) {
  yield put({ type: UPDATE_USER_BY_ID_LOADING });
  try {
    yield call(api, {
      method: "PUT",
      url: "https://6824mjxh23.execute-api.us-east-1.amazonaws.com/dev/id/{id}",
      body,
    });
    yield put({ type: UPDATE_USER_BY_ID_SUCCESS, data: true });
    yield put({ type: GET_USERS });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({ type: UPDATE_USER_BY_ID_ERROR, errorMessage: errorMessage });
  }
}

function* getUserById({ body }) {
  yield put({ type: GET_USER_BY_ID_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: "https://6824mjxh23.execute-api.us-east-1.amazonaws.com/dev/id/{id}",
      body,
    });
    yield put({ type: GET_USER_BY_ID_SUCCESS, data: data });
  } catch (error) {
    const errorMessage = errorhandling(error);
    yield put({
      type: GET_USER_BY_ID_ERROR,
      errorMessage: errorMessage,
    });
  }
}

export default function* watchUserPermissionsSaga() {
  yield takeLatest(CREATE_USER, createUser);
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(UPDATE_USER_BY_ID, updateUserById);
  yield takeLatest(GET_USER_BY_ID, getUserById);
}
