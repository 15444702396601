import { combineReducers } from "redux";
import {
  CREATE_CONFIGURATION_LOADING,
  CREATE_CONFIGURATION_SUCCESS,
  CREATE_CONFIGURATION_ERROR,
  GET_CONFIGURATION_LOADING,
  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_ERROR,
  UPDATE_CONFIGURATION_LOADING,
  UPDATE_CONFIGURATION_SUCCESS,
  UPDATE_CONFIGURATION_ERROR,
  DOWNLOAD_FILE_FROM_S3_LOADING,
  DOWNLOAD_FILE_FROM_S3_SUCCESS,
  DOWNLOAD_FILE_FROM_S3_ERROR,
  EXECUTE_PROMPT_BY_ID_LOADING,
  EXECUTE_PROMPT_BY_ID_SUCCESS,
  EXECUTE_PROMPT_BY_ID_ERROR,
} from "./actionTypes";

const configuration = (
  state = {
    loading: false,
    data: {},
    error: "",
  },
  action
) => {
  switch (action.type) {
    case EXECUTE_PROMPT_BY_ID_LOADING:
    case UPDATE_CONFIGURATION_LOADING:
    case CREATE_CONFIGURATION_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case EXECUTE_PROMPT_BY_ID_SUCCESS:
    case UPDATE_CONFIGURATION_SUCCESS:
    case CREATE_CONFIGURATION_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case EXECUTE_PROMPT_BY_ID_ERROR:
    case UPDATE_CONFIGURATION_ERROR:
    case CREATE_CONFIGURATION_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const configurations = (
  state = {
    loading: false,
    data: {},
    error: "",
  },
  action
) => {
  switch (action.type) {
    case GET_CONFIGURATION_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_CONFIGURATION_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_CONFIGURATION_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const outputFileById = (
  state = {
    loading: false,
    data: {},
    error: "",
  },
  action
) => {
  switch (action.type) {
    case DOWNLOAD_FILE_FROM_S3_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case DOWNLOAD_FILE_FROM_S3_SUCCESS: {
      const { id, data } = action.data
      return {
        loading: false,
        data: {
          ...state.data,
          [id]: data,
        },
        error: "",
      };
    }
    case DOWNLOAD_FILE_FROM_S3_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  configuration,
  configurations,
  outputFileById,
});
