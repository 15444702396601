import { combineReducers } from "redux";
import {
  CREATE_USER_LOADING,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USER_BY_ID_LOADING,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  UPDATE_USER_BY_ID_LOADING,
  UPDATE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_ERROR,
} from "./actionTypes";

const userAdded = (
  state = {
    loading: false,
    data: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CREATE_USER_LOADING: {
      return {
        loading: true,
        data: false,
        error: "",
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case CREATE_USER_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const userById = (
  state = {
    loading: false,
    data: {},
    error: "",
  },
  action
) => {
  switch (action.type) {
    case GET_USER_BY_ID_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_USER_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_USER_BY_ID_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const users = (
  state = {
    loading: false,
    data: {},
    error: "",
  },
  action
) => {
  switch (action.type) {
    case GET_USERS_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_USERS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const userUpdated = (
  state = {
    loading: false,
    data: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case UPDATE_USER_BY_ID_LOADING: {
      return {
        loading: true,
        data: false,
        error: "",
      };
    }
    case UPDATE_USER_BY_ID_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case UPDATE_USER_BY_ID_ERROR: {
      return {
        loading: false,
        data: false,
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  users,
  userAdded,
  userById,
  userUpdated,
});
