export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const CREATE_CONFIGURATION_LOADING = "CREATE_CONFIGURATION_LOADING";
export const CREATE_CONFIGURATION_SUCCESS = "CREATE_CONFIGURATION_SUCCESS";
export const CREATE_CONFIGURATION_ERROR = "CREATE_CONFIGURATION_ERROR";

export const GET_CONFIGURATIONS  = "GET_CONFIGURATIONS";
export const GET_CONFIGURATION_LOADING = "GET_CONFIGURATION_LOADING";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_ERROR = "GET_CONFIGURATION_ERROR";

export const UPDATE_CONFIGURATION  = "UPDATE_CONFIGURATION";
export const UPDATE_CONFIGURATION_LOADING = "UPDATE_CONFIGURATION_LOADING";
export const UPDATE_CONFIGURATION_SUCCESS = "UPDATE_CONFIGURATION_SUCCESS";
export const UPDATE_CONFIGURATION_ERROR = "UPDATE_CONFIGURATION_ERROR";

export const GET_TEST_DATA_BY_ID  = "GET_TEST_DATA_BY_ID";
export const GET_TEST_DATA_BY_ID_LOADING = "GET_TEST_DATA_BY_ID_LOADING";
export const GET_TEST_DATA_BY_ID_SUCCESS = "GET_TEST_DATA_BY_ID_SUCCESS";
export const GET_TEST_DATA_BY_ID_ERROR = "GET_TEST_DATA_BY_ID_ERROR";

export const UPLOAD_FILE_TO_S3  = "UPLOAD_FILE_TO_S3";
export const UPLOAD_FILE_TO_S3_LOADING = "UPLOAD_FILE_TO_S3_LOADING";
export const UPLOAD_FILE_TO_S3_SUCCESS = "UPLOAD_FILE_TO_S3_SUCCESS";
export const UPLOAD_FILE_TO_S3_ERROR = "UPLOAD_FILE_TO_S3_ERROR";

export const DOWNLOAD_FILE_FROM_S3  = "DOWNLOAD_FILE_FROM_S3";
export const DOWNLOAD_FILE_FROM_S3_LOADING = "DOWNLOAD_FILE_FROM_S3_LOADING";
export const DOWNLOAD_FILE_FROM_S3_SUCCESS = "DOWNLOAD_FILE_FROM_S3_SUCCESS";
export const DOWNLOAD_FILE_FROM_S3_ERROR = "DOWNLOAD_FILE_FROM_S3_ERROR";

export const EXECUTE_PROMPT_BY_ID  = "EXECUTE_PROMPT_BY_ID";
export const EXECUTE_PROMPT_BY_ID_LOADING = "EXECUTE_PROMPT_BY_ID_LOADING";
export const EXECUTE_PROMPT_BY_ID_SUCCESS = "EXECUTE_PROMPT_BY_ID_SUCCESS";
export const EXECUTE_PROMPT_BY_ID_ERROR = "EXECUTE_PROMPT_BY_ID_ERROR";